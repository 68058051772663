import http from '@/utils/http';

// 获取国家/地区
export const getQiniuToken = (params) => {
    return http.request({
        url: "/v1/qiniu/token/get",
        method: 'post',
        data: params,
    })
}

export const uploadQiniu = (params) => {
    return http.request({
        url: "//upload.qiniu.com/",
        method: 'post',
        data: params,
    })
}