
<template>
<div style="position: relative;">
  <Row>
    <Row>
      <div
        class="img-container"
        v-bind:style="{ width: 100*width/height+'px', height: '100px','background-image':'url('+getImgPath()+')' }"
        style="background-repeat: no-repeat;background-size: cover;background-position: center;"
      >
        <div class="hover" @click="openEditor"></div>
      </div>
    </Row>
    <Row>
      <Button type="primary" shape="circle" icon="ios-eye" @click="previewImg" v-if="previewButton"></Button>
      <Button type="primary" shape="circle" icon="ios-trash" @click="clearImg" v-if="clearButton"></Button>
    </Row>
  </Row>

  <Modal v-model="modal_preview" title="预览" :transfer=true>
      <Row type="flex" justify="center" align="middle" >
        <img :src="getImgPath(false)" alt style="height: 100%" />
      </Row>
  </Modal>

  <Modal v-model="modal" title="编辑" @on-ok="ok" @on-cancel="cancel" :transfer=true>
    <Row>
      <input
        type="file"
        accept="image/png, image/jpeg, image/gif, image/jpg"
        @change="handleChange"
        ref="fileinput"
        class="fileinput"
        style="display: none"
      >
      <Button type="primary" @click="onBtnUploadClick" icon="image">上传图片</Button>
      <label>比例:
        <Input v-model="width" placeholder="长" style="width: 100px"></Input>
        <Input v-model="height" placeholder="宽" style="width: 100px"></Input>
      </label>
    </Row>
    <br />
    <Row>
          <img ref="targetImg" style="max-width: 100%; min-height: 300px; " />
          <Row type="flex" justify="center" align="middle" >
            <div class="preview"></div>
          </Row>
    </Row>
    <div slot="footer">
      <template >
        <Tooltip content="保存原图,不作裁剪" placement="top-start">
          <Checkbox v-model="origin">保存原图</Checkbox>
        </Tooltip>
        <Button type="primary" :loading="modal_loading" @click="save">保存</Button>
      </template>
    </div>
  </Modal>
</div>
</template>
<script>

import Cropper from "cropperjs";
import "./cropper.min.css";
import { getQiniuToken, uploadQiniu } from '@/api/qiniu';

export default {
name: "img-editor",
props: [
  "prefix",   // this.$conf.img_prefix,
  "value",
  "dir",
  "w",
  "h",
  "short_name",
  "previewButton",
  "clearButton",
  "cdn", // 奥运会 运动 电竞
  "cdnkey", // 指定文件名称
],
data() {
  return {
    image : this.value,
    cropper: null,
    modal : false,
    modal_loading: false,
    result: null,
    origin: false,
    file  : null,
    suffix: "?imageView2/2/h/100",
    width : this.w,
    height: this.h,
    preview: false,
    modal_preview: false,
  };
},
mounted() {
},

computed: {
},

methods: {
  getImgPath( need_thumbs){
    need_thumbs = need_thumbs === false ? false: true;
    let self = this;
    // console.log(self.image, self.prefix + '/'+ self.dir + '/' + self.image + self.suffix);
    if (self.image) {
        if (self.image.indexOf('https://') > -1){
            return self.image
        }else if ( self.image.indexOf('http://') > -1){
            return self.image
        }
        if ( self.image.indexOf('?vframe/jpg/offset/1') > -1){
            return encodeURI(self.prefix + '/'+ self.dir + '/' + self.image);
        }
        if (need_thumbs){
          return encodeURI(self.prefix + '/'+ self.dir + '/' + self.image + self.suffix);
        }else{
          return encodeURI(self.prefix + '/'+ self.dir + '/' + self.image);
        }
    } else {
        return "";
    }
  },
  // 
  _emit( img ){
    let self = this;
    self.$emit("input", img)
  },
  setAspectRatio() {
    if (this.cropper && this.width && this.height)
      this.cropper.setAspectRatio(this.width / this.height);
  },
  openEditor() {
    this.modal = true;
    let self = this;
    if (self.image){
      let img = self.$refs.targetImg;
      img.onload = function() {
        if (self.cropper) {
          self.cropper.destroy();
        }
        self.cropper = new Cropper(img, {
          viewMode: 1,
          dragMode: "move",
          restore: false,
          aspectRatio:
            self.width && self.height ? self.width / self.height : null,
          center: false,
          highlight: true,
          cropBoxMovable: true,
          toggleDragModeOnDblclick: false
        });
      };
      img.src = self.getImgPath(false);
    }
  },
  save() {},
  ok() {},
  cancel() {},
  clearImg() {
    this.image = "";
  },
  previewImg() {
    this.preview = !this.preview;
    if (this.image){
      this.modal_preview = true;
    }
  },
  onBtnUploadClick() {
    this.$refs.fileinput.click();
  },
  handleChange(e) {
    let self = this;
    let file = e.target.files[0];
    let reader = new FileReader();
    this.file = file;
    reader.onload = e => {
      // this.cropper.replace(reader.result, true); // 这里必须设置true这个参数，否则旋转会有bug
      let img = self.$refs.targetImg;
      img.onload = function() {
        if (self.cropper) {
          self.cropper.destroy();
        }
        self.cropper = new Cropper(img, {
          viewMode: 1,
          dragMode: "move",
          restore: false,
          aspectRatio: self.width && self.height ? self.width / self.height : null,
          center: false,
          highlight: true,
          cropBoxMovable: true,
          toggleDragModeOnDblclick: false
        });
      };
      img.src = e.currentTarget.result;
      reader.onload = null;
    };
    reader.readAsDataURL(file);
  },
  save() {
    let self = this;
    let file = this.cropper.getCroppedCanvas().toDataURL();
    this.modal_loading = true;
    self.modal_loading = false;
    let dir = self.dir ? self.dir : "upload/";
    if (!/\/$/.test(dir)) {
      dir += "/";
    }
    if (self.cdnkey){
      var file_name = self.cdnkey
    }else if (typeof self.short_name != "undefined"){
      var file_name = Math.random()
        .toString(36).substr(2);
    }else{
      var file_name = null
    }
    if (file_name){
      var qiniu_key = dir + file_name
    }else{
      var qiniu_key = null
    }

    if (this.origin) {
      if (self.file) {
          getQiniuToken({
            dir: dir,
            cdn: self.cdn,
            key: qiniu_key,
          }).then(response => {
            if (response.data.code == 0) {
              let formdata = new FormData();
              formdata.append("file", self.file);
              if (qiniu_key){
                formdata.append("key", qiniu_key);
              }
              formdata.append("token", response.data.data.token);
              uploadQiniu(formdata).then(response => {
                  self.modal = false;
                  self.$Message.success("保存成功");
                  if (qiniu_key){
                    self.image = file_name;
                  }else{
                    self.image = response.data.hash;
                  }
                });
            }
          });
      }
    } else if (this.cropper){
        console.log(2)
        this.cropper.getCroppedCanvas().toBlob(function(blob) {
            getQiniuToken({
              dir: dir,
              cdn: self.cdn,
              key: qiniu_key,
            }).then(response => {
              if (response.data.code == 0) {
                let formdata = new FormData();
                formdata.append("file", blob);
                if (qiniu_key){
                  formdata.append("key", qiniu_key);
                }
                formdata.append("token", response.data.data.token);
                uploadQiniu(formdata).then(response => {
                    // console.log(response)
                    self.modal_loading = false;
                    self.modal = false;
                    self.$Message.success("保存成功");
                    // self.image = response.data.key;
                    if (qiniu_key){
                      self.image = file_name;
                    }else{
                      self.image = response.data.hash;
                    }
                  });
              }
            });
        });
    }
  }
},
watch: {
  image(value) {
      // this.$emit("input", this.image);
      this._emit( value );
  },
  value(value) {
      this.image = value;
  },
  w(value) {
      this.width = value;
  },
  h(value) {
      this.height = value;
  },
  width(value) {
      this.setAspectRatio();
  },
  height(value) {
      this.setAspectRatio();
  },
},
};
</script>

<style scoped >
/* <style scoped lang="less"> */
  .cropper {
    box-sizing: border-box;
    border: 1px solid #c3c3c3;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }

  .cropper .preview {
      max-width: 100%;
      max-height: 100%;
      overflow: hidden;
    }

  .image-editor-container {
    height: 300px;
  }

  .img-container {
    width: 100px;
    height: 100px;
    position: relative;
    display: inline-block;
    text-align: center;
    line-height: 60px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-right: 4px;
    background: rgba(0, 0, 0, 0.3);
  }

  .img-container .hover {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: pointer;
      background: rgba(0, 0, 0, 0);
  }
  .ck-preview {
    margin-left: 10px;
  }
</style>  
